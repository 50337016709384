import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private _httpClient: HttpClient,
    private _tokenService: TokenService
  ) { }

  signUp(user: {
    name: string;
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post(environment.apiUrl + 'auth/register', user);
  }

  login(user: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post(environment.apiUrl + 'auth/login', user);
  }

  isSignedIn(): boolean {
    const token = this._tokenService.getToken;
    return token != null ;
  }

  socialMediaLogin(socialMedia: string, action: string, source: string){
    const url = environment.apiUrl + `auth/${socialMedia}?action=${action}&source=${source}`;
    window.location.replace(url);
  }

  forgotPassword(obj: any): Observable<any> {
    return this._httpClient.post(environment.apiUrl + 'auth/forgot-password', obj);
  }

  resetPassword(obj: any): Observable<any> {
    return this._httpClient.post(environment.apiUrl + 'auth/reset-password', obj);
  }
verifyEmail(code: string, email: string){
  return this._httpClient.post(environment.apiUrl + `auth/verify-email?token=${code}&username=${email}`, {});
}
}

