import { AbstractControl, FormGroup } from '@angular/forms';

// custom validator to check that two fields match
// export function MustMatch(controlName: string, matchingControlName: string) {
//   return (formGroup: FormGroup) => {
//     const control = formGroup.controls[controlName];
//     const matchingControl = formGroup.controls[matchingControlName];

//     // return null if controls haven't initialized yet
//     if (!control || !matchingControl) {
//       return null;
//     }

//     // set error on matchingControl if validation fails
//     if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
//       // return if another validator has already found an error on the matchingControl
//       return;
//     }

//     // set error on matchingControl if validation fails
//     if (control.value !== matchingControl.value) {
//       matchingControl.setErrors({ mustMatch: true });
//     } else {
//       matchingControl.setErrors(null);
//     }

//     return null;
//   };
// }

export function mustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: AbstractControl) => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);

    if (matchingControl?.errors && !matchingControl.errors['mustMatch']) {
      // Return if another validator has already found an error on the matchingControl
      return;
    }

    // Set error on matchingControl if validation fails
    if (control?.value !== matchingControl?.value) {
      matchingControl?.setErrors({ mustMatch: true });
    } else {
      matchingControl?.setErrors(null);
    }
  };
}

export const regex = {
    phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
    // email: /^([\w-](\.?[a-zA-Z0-9_])+@([\w-]+\.)+[\w-]{2,4})?$/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    name: /(?=.*[A-Za-z])^[A-Za-z0-9 ]+$/,
    address: /(?=.*[A-Za-z])^[A-Za-z0-9#\d,\s.-]+$/,
    alphabet: /^[a-zA-Z]*$/,
    number: /^(0|[1-9][0-9]*)$/,
    webAddress: /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
};
